import React, { Component } from 'react'
import ReactIframeResizer from 'react-iframe-resizer-super'
import ResponsiveEmbed from 'react-responsive-embed'

export default class Embed extends Component {
  static defaultProps = {
    src: '',
  }

  get isYoutube() {
    return this.props.src.includes('youtube')
  }

  get component() {
    return this.isYoutube ? ResponsiveEmbed : ReactIframeResizer
  }

  render() {
    const props = { ...this.props }
    if (!this.isYoutube) {
      props.checkOrigin = false
    }

    if (this.isYoutube) {
      return <this.component {...props} />
    }

    return (
      <div className="responsive-embed">
        <this.component {...props} />
      </div>
    )
  }
}
