import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import ProductCard, {
  Image as ProductCardImage,
  Content as ProductCardContent,
  Header as ProductCardHeader,
  Body as ProductCardBody,
  Footer as ProductCardFooter
} from '../components/product-card'
import Link from '../components/link'
import Banner from '../components/banner'
import Embed from '../components/embed'

const getBanner = (props) => (
  <Banner {...props} />
)

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { url } = node.data.target.fields
      const src = url[Object.keys(url)[0]]

      return <Embed src={src} />
    }
  }
}

const Page = ({ data }) => {
  const { title, body, infoCards, banner, customMeta } = data.contentfulPage

  return (
    <Layout banner={banner ? getBanner(banner) : null}>
      <SEO title={title} customMeta={customMeta} />
      <div className="container">
        {body && body.json ? documentToReactComponents(body.json, renderOptions) : null}
        {infoCards ? (
          <div className="row align-items-stretch">
            {infoCards.map((infoCard, i) => (
              <ProductCard key={i}>
                {infoCard.featuredImage ? (
                  <ProductCardImage src={infoCard?.featuredImage?.localFile?.publicURL} alt={infoCard?.featuredImage?.title}/>
                ) : ''}
                <ProductCardContent>
                  <ProductCardHeader>{infoCard.title}</ProductCardHeader>
                  <ProductCardBody>{documentToReactComponents(infoCard.body.json)}</ProductCardBody>
                  <ProductCardFooter>
                    {infoCard.lInks ? (
                      <ul>
                        {infoCard.lInks.map((link, i) => (
                          <li key={i}>
                            <Link href={link.page ? `/${link.page.slug}` : link.link}>{link.title}</Link>
                          </li>
                        ))}
                      </ul>
                    ) : ''}
                  </ProductCardFooter>
                </ProductCardContent>
              </ProductCard>
            ))}
          </div>
        ) : ''}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    contentfulPage(id: { eq: $id }) {
      customMeta {
        tag
        attributes {
          key
          value
        }
      }
      title
      body {
        json
      }
      infoCards {
        title
        body {
          json
        }
        featuredImage {
          title
          localFile {
            publicURL
          }
        }
        lInks {
          ... on ContentfulNavItemExternal {
            title
            link
          }
        }
      }
      banner {
        ...BannerFragment
      }
    }
  }
`
export default Page
