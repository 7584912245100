import React from 'react'

export default ({ children }) => (
  <div className="product-card__wrapper">
    <div className="product-card">
      <div className="product-card__container">
        {children}
      </div>
    </div>
  </div>
)

export Image from './image'
export Content from './content'
export Header from './header'
export Body from './body'
export Footer from './footer'
